import Vue from 'vue'
import VueRouter from 'vue-router'

const home = () => import(/* webpackChunkName: "home" */ "../views/home.vue")
const about = () => import(/* webpackChunkName: "home" */ "../views/about.vue")
const blog = () => import(/* webpackChunkName: "home" */ "../views/blog.vue")
const features = () => import(/* webpackChunkName: "home" */ "../views/features.vue")
const forbusiness = () => import(/* webpackChunkName: "home" */ "../views/ForBusiness.vue")
const pricing = () => import(/* webpackChunkName: "home" */ "../views/pricing.vue")
const regist = () => import(/* webpackChunkName: "home" */ "../views/regist.vue")
const support = () => import(/* webpackChunkName: "home" */ "../views/support.vue")

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: home,
    name:"home"
  },
  {
    path: '/home',
    component: home,
    name:"home"
  },{
    path: '/about',
    component: about,
    name:"about"
  },{
    path: '/blog',
    component: blog,
    name:"blog"
  },{
    path: '/features',
    component: features,
    name:"features"
  },{
    path: '/forbusiness',
    component: forbusiness,
    name:"forbusiness"
  },{
    path: '/pricing',
    component: pricing,
    name:"pricing"
  },{
    path: '/regist',
    component: regist,
    name:"regist"
  },{
    path: '/support',
    component: support,
    name:"support"
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router