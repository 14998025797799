import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
Vue.config.productionTip = false

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en_us", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'en_us': require('./assets/languages/en_US.json'),
  }
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
