<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
        show_navbar:false,
    };
  },
  mounted(){
    console.log(this.$route);
  }
}
</script>


<style>
@import "assets/css/font-awesome.css";
@import "assets/css/botstrap.css";
@import "assets/css/main.css";
@import "assets/css/slick.css";
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
   margin-top: 60px; */
}


</style>
